<template>
  <div class="brands-wrap">
    <div class="w-full pt-14 md:pt-24 pb-20">
      <div class="w-full max-w-rifyo mx-auto">
        <div class="max-xl:px-7">
          <div class="py-4 flex align-center" @click="goBack()">
            <img
              alt="image"
              src="/playground_assets/arrow-left.svg"
              class="mr-4 cursor-pointer"
            />
            <span class="tracking-widest cursor-pointer"
              >Back To List Of Brands</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap mb-20">
        <div class="w-full md:w-1/2 md:order-2">
          <img
            :src="dataDetail.photo" 
            :alt="dataDetail.title"
            class="w-full object-cover hidden md:block">
          <img
            :src="dataDetail.photo_mobile" 
            :alt="dataDetail.title"
            class="w-full object-cover md:hidden">
        </div>
        <div class="w-full md:w-1/2 md:order-1">
          <div class="relative w-full h-full bg-[#f2f2f2]">
            <div class="relative md:absolute md:top-[50%] md:left-[50%] md:translate-x-[-50%] md:translate-y-[-50%] w-[242px] lg:w-[400px] p-8 md:p-0">
              <img
              :src="dataDetail.logo"
              :alt="dataDetail.title"
              class="w-full object-cover">
            </div>
          </div>
        </div>
      </div>
      <div class="w-full max-w-rifyo mx-auto">
        <div class="w-full md:w-2/3 mx-auto overflow-hidden max-xl:px-7">
          <div class="entry-content mb-8" v-html="dataDetail.description"></div>
          <div class="cursor-pointer px-6 py-2.5 border border-black inline-block hover:bg-black hover:text-white">
            <a :href="dataDetail.url" target="_blank" rel="noopener noreferrer" class="brand-card__link">Visit Official Website</a>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full py-12 bg-[#eae3d9]">
      <div class="w-full max-w-rifyo mx-auto">
        <div class="w-full lg:w-9/12 mx-auto flex flex-wrap justify-between items-center max-xl:px-7">
          <h1 class="brand-cta mb-8 lg:mb-0">Interesting to have their products?</h1>
          <a
            href="https://wa.me/6287808880288"
            class="bg-black inline-flex justify-center items-center py-3 px-5"
          >
            <img
              alt="image"
              src="/playground_assets/chat.svg"
              class="ic-contact-us"
            />
            <span class="text-contact-us">Contact Us</span>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import XClose from '../components/x-close';
  import Footer from '../components/footer';
  import Api from '../api';

  export default {
    components: {
      Footer,
      XClose,
    },

    computed: {
      url() {
        return window.location.href ? window.location.href : null;
      },
    },

    data() {
      return {
        dataDetail: [],
        isError: false,
        msg_err: null,
        isLoading: false,
      };
    },

    async created() {
      await this.getBrands();
    },

    methods: {
      async getBrands() {
        try {
          const response = await this.$http.get(
            Api.brandsUrl + '/' + this.$route.params.slug + '/detail'
          );
          this.dataDetail = response.data.data;
        } catch (error) {
          this.msg_err = error.message;
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 5000);
        }
      },
      goBack: function () {
        this.$router.go(-1);
      },
    },

    name: 'BrandsDetail',
  };
</script>

<style scoped>
.brand-card__link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.24em;
  text-transform: capitalize;
}
.brand-cta {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #1F252C;
}

.entry-content {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1F252C;
}

.text-contact-us {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.24em;
  text-transform: capitalize;
  color: #FFFFFF;
}
</style>
